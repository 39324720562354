<template>
  <div class="game-room-item" :id="id">
    <template v-if="gameDetails.picture_arr == null || gameDetails.picture_arr.length < 0">
      <div class="img-container">
        <img src="../../../../assets/icons/game_room_icon.png" class="game-image" alt="Game Room">
      </div>
    </template>
    <template v-else>
      <div class="img-container">
        <img :src="gameDetails.picture_arr[0]" class="game-image" alt="Game Room">
      </div>
    </template>
    <div class="delete-btn" @click="deleteRoom" v-if="userRole === 'admin'">
      <img src="../../../../assets/icons/cross.svg" alt="delete">
    </div>
    <div class="flex-item">
      <div class="room-details">
        <p class="room-name">{{gameDetails.name}} 
          <ToolTip class="tooltip-position" :positionTop="positionTop" :id="[id ? 'step-2' : '']">
            <template v-slot:exchange>{{gameDetails.contract_spec.exchange}}</template>
            <template v-slot:product>{{gameDetails.contract_spec.product}}</template>
            <template v-slot:balance>{{gameDetails.currency}}{{gameDetails.initial_cash | moneyFormat}}</template>
            <template v-slot:initial-margin>{{gameDetails.currency}}{{gameDetails.contract_spec.initial_margin_val | moneyFormat}}</template>
            <template v-slot:maintenance-margin>{{gameDetails.currency}}{{gameDetails.contract_spec.maintenance_margin_val | moneyFormat}}</template>
            <template v-slot:duration>{{gameDetails.duration}}</template>
            <template v-slot:timer>{{gameDetails.timer}}</template>
          </ToolTip>
        </p>
        <p class="player-count" v-if="userRole === 'admin'">
          <template v-if="isPrivate">
            Player: {{gameDetails.no_of_players}}/{{gameDetails.max_players}}
          </template>
          <template v-else>
            No of unique player played: {{gameDetails.no_of_players}} <br>
            No of active sessions: {{gameDetails.concurrent_players}} <br>
            No of total games: {{gameDetails.no_of_games}}
          </template>
        </p>
        <div>
          <template v-if="userRole === 'admin'">
            <p @click="toggleGame" class="gameState">
              {{activeState == 0 ? 'Inactive' : 'Active'}}
            </p>

            <template v-if="isPrivate">
              <p @click="editGame" :class="{disabled: disallowStatus.includes(gameDetails.status), editGame}">
                EDIT
              </p>
            </template>
            <p @click="editGame" class="editGame" v-else>
              EDIT
            </p>
          </template>
         <template v-if="userRole === 'trainer' && gameDetails.owner_id == userProfileData.id">
            <template v-if="isPrivate">
              <p @click="toggleGame" class="gameState">
                {{activeState == 0 ? 'Inactive' : 'Active'}}
              </p>
              <p @click="editGame" :class="{disabled: disallowStatus.includes(gameDetails.status), editGame}">
                EDIT
              </p>
            </template>
          </template>
        </div>
      </div>
      <div class="span-right">
        <div class="actions">
          <div class="circular-container" v-if="isPrivate">
          <font-awesome-icon icon="lock" />
        </div>
        <SubmitButton @onButtonClick="joinRoom" :class="{disabled: !activeState}">Join</SubmitButton>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton';
import ToolTip from "@/components/ToolTip";
import {eventBus} from '@/main.js';

//API
import { gameSessionStore } from '@/services/api/game.js'; 
import { deleteGame, setGameActive} from '@/services/adminAPI/game.js';

export default {
  props:['gameDetails','isPrivate','positionTop','id'],
  data(){
    return{
      showPasswordOverlay: false,
      activeState: 0,
      disallowStatus :['ENDED','ONGOING','PAUSED']
    }
  },
  created(){
    this.activeState = this.gameDetails.is_active;
  },
  components:{
    SubmitButton,
    ToolTip
  },
  methods:{
    async joinRoom(){

      if(!this.activeState) return;

      if(this.isPrivate){
        if(this.userRole === 'admin'){
          this.$router.push(`/admin/dashboard/game/private/${this.gameDetails.id}`);
        }else if(this.userRole == 'trainer'){
          this.$router.push(`/dashboard/game/private/${this.gameDetails.id}/trainer`);
        }else{  
          const {value: password } = await this.$swal({
            title: 'Enter the game password',
            input: 'password',
            confirmButtonText: 'Enter',
            inputValidator: (value) => {
              if (!value) {
                return 'Please enter the password!'
              }
            }
          })

          if(password){
            try {
              const gameSessionStoreAPI = await gameSessionStore({password},this.gameDetails.id);
    
              this.$store.commit('setCurrentGameSession',gameSessionStoreAPI.data.response.gameSession.id);

              this.$router.push(`/dashboard/game/private/${this.gameDetails.id}`);
            } catch (error) {
              this.$swal({
                icon: 'error',
                title: 'Oops!',
                text: error.response.data.userMessage
              });
            }
          }
        }
      }else{
        if(this.userRole === 'admin'){
          this.$router.push(`/admin/dashboard/game/public/${this.gameDetails.id}`);
        }else{
          this.$router.push(`/dashboard/game/public/${this.gameDetails.id}`);
        }
      }

      //Update the game details to store to display on tooltip
      this.$store.commit('setCurrentGame',this.gameDetails);
    },
    async deleteRoom(){
      this.$swal({
        title: 'Are you sure to delete the game room?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result)  => {
        if (result.isConfirmed) {

          eventBus.$emit('loader',true);

          try {
            await deleteGame(this.gameDetails.id);
            await this.$store.dispatch('callGameList');
            eventBus.$emit('loader',false);
          } catch (error) {
            console.log(error);
            eventBus.$emit('loader',false);
          }
        }
      })
    },
    async toggleGame(){
      this.activeState == 0 ? this.activeState = 1 : this.activeState = 0;

      eventBus.$emit('loader',true);

      try {
        await setGameActive(this.gameDetails.id,{is_active: this.activeState});
        await this.$store.dispatch('callGameList');
        eventBus.$emit('loader',false);
      } catch (error) {
        console.log(error);
        eventBus.$emit('loader',false);
      }
     },
     editGame(){
       if(!this.disallowStatus.includes(this.gameDetails.status)){
         if(this.userRole === 'trainer'){
            this.$router.push({name: 'TrainerEditGame',params:{id: this.gameDetails.id}})
          }else{
            this.$router.push({name: 'AdminEditGame',params:{id: this.gameDetails.id}})
          }
       }
     }
  },
  computed:{
    moneyFormat(){
      var formatter = new Intl.NumberFormat('en-US');
      return formatter.format(this.gameDetails.initial_cash);
    }
  }
}
</script>

<style lang="scss" scoped>

  .disabled{
    cursor: not-allowed;
  }

  .delete-btn{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    border-radius: 25px;
    transition: background .1s linear;
    cursor: pointer;

    &:hover{
      background: #eeeded;
    }

    img{
      height: 10px;
      width: auto;
    }
  }

  .gameState, 
  .editGame{
    display: table;
    font-size: 11px;
    opacity: 0.5;
    text-transform: uppercase;
    padding: 3px 5px;
    transition: background-color .1s linear;
    display: inline;
    cursor: pointer;

    &:hover{
      background-color: rgba($color: #050505, $alpha: 0.2);
    }
    
    &.disabled{
      cursor: not-allowed;
    }
  }

  .game-room-item{
    position: relative;
    border: 1px solid #D7D8D6;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    max-width: 485px;

    @media screen and (max-width:768px){
      flex-basis: 100%;
    }
  }

  .img-container{
    margin-right: 20px;
    object-fit: contain;
    height:auto;
    width: 60px;

    @media screen and (max-width:450px){
      width: 40px;
    }
  }

  .room-details{
    position:relative;
    .room-name{
      font-size: 15px;
      font-weight: 700;
      display: inline-flex;
      word-break: break-word;

      @media screen and (max-width:450px){
        font-size: 14px;
      }
    }

    .player-count{
      color: #292727;
      font-size: 13px;
    }
  
    .tooltip-position{
      margin-left: 10px;
    }
  }

  .flex-item{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .actions{
    margin-left: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .circular-container{
      height: 30px;
      width: 30px;
      border-radius: 17px;
      background-color: var(--gray414241);
      display: grid;
      place-items: center;
      margin-right: 20px;

      @media screen and (max-width:450px){
        height: 25px;
        width: 25px;
      }

      svg{
        color: white;
        width: 0.7em;

        @media screen and (max-width:450px){
          width: 0.5em;
        }
        
      }
    }
  }
</style>