<template>
  <div class="tooltip">
    <span class="info-button" 
    tabindex="0"
      @mouseenter="showTooltip = true" 
      @mouseleave="showTooltip = false"
      @focus="showTooltip = true"
      @focusout="showTooltip = false" 
      :style="{color: defaultBlackIcon ? 'initial' : 'white' }">
      <font-awesome-icon icon="info-circle" />
    </span>
    <span class="tooltip--text" :class="{left : alignLeft, top: positionTop}" v-show="showTooltip" >
      <div>Exchange: <slot name="exchange"></slot></div>
      <div>Product: <slot name="product"></slot></div>
      <div>Initial Cash Balance: <slot name="balance"></slot></div>
      <div>Initial Margin: <slot name="initial-margin"></slot></div>
      <div>Maintenance Margin: <slot name="maintenance-margin"></slot></div>
      <div>Game Duration: <slot name="duration"></slot> Days</div>
      <div>Trading Day: <slot name="timer"></slot> Seconds</div>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToolTip",
  props:{
    alignLeft:{
      default: true,
      required: false,
      type: Boolean
    },
    defaultBlackIcon:{
      default: true,
      required: false,
      type: Boolean
    },
    positionTop:{
      default: false,
      required: false,
      type: Boolean
    }
  },
  methods:{
    mouseClick(){
      console.log('click');
      this.showTooltip = true;
    },
    outsideClicked(){
      this.showTooltip = false;
    },
    toggleTooltip(){
      this.showTooltip = !this.showTooltip
    },
    test(){
      console.log('click');
    }
  },
  data(){
    return{
      showTooltip: false,
    }
  }
};
</script>

<style lang="scss" scoped>

.tooltip {
    box-sizing: border-box;
    margin: 0;
    display: block;
  position: relative;
  color: $black;

  .default--text {
    display: inline-block;
    text-align: center;
  }
  &--text {
    background-color: $grayDark;
    color: $white;
    position: absolute;
    max-width: 40rem;
    width: max-content;
    // visibility: hidden;
    text-align: left;
    padding: 1rem;
    border-radius: 0.5rem;
    z-index: 1000;
    font-size: 13px;


    @media screen and (max-width:450px){
      font-size: 12px;
    }
  }
  &:active &--text {
    visibility: visible;
  }
  .right {
    top: -1rem;
  }
  .left {
    top: -8rem;
    transform: translateX(-50%);

    @media screen and (max-width:450px){
      transform: translateX(-70%);

    }
  }
  .top{
     top: 2rem;
    transform: translateX(-50%);

    @media screen and (max-width:450px){
      transform: translateX(-70%);

    }
  }
}
</style>
