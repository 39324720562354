<template>
  <div>
    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks" v-if="!completed"></v-tour>
  </div>
</template>

<script>
  export default {
    name: 'my-tour',
    data () {
      return {
        completed: false,
        steps: [
          {
            target: '#step-1',
            content: `Pick one product and click <strong>JOIN</strong> to start the game`,
            params: {
              highlight: true,
              placement: 'right',
              enableScrolling: false
            }
          },
          {
            target: '#step-2',
            content: `View <strong>INFO</strong> for the details of the game`,
            params: {
              highlight: true,
              enableScrolling: false
            }
          },
        ],
        myCallbacks: {
          onFinish: this.onFinishCallback,
        }
      }
    },
    mounted: function () {

    },
    created(){
      const tutorial = localStorage.getItem('dashboardTutorial');

      if(tutorial){
        this.completed = true;
      }

      setTimeout(() => {
        if(!this.completed){
          this.$tours['myTour'].start();
          localStorage.setItem('dashboardTutorial',true)
        }
      }, 1000);
    },
    methods:{
      onFinishCallback(){

      }
    }
  }
</script>