<template>
  <div class="wrapper">
    <DashboardTour v-if="userRole !== 'admin'"/>
    <draggable class="game-list" v-model="draggableData" @end="updateGameOrder" v-if="userRole === 'admin'">
      <template v-for="(rooms,index) in draggableData" >
        <template v-if="index == 0 || index == 1"> 
          <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="true" v-if="index == 0" id="step-1"/>
          <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="true" v-else/>
        </template>
        <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="false" v-else/>
      </template>    
    </draggable>

    <div class="game-list" v-else>
      <template v-for="(rooms,index) in draggableData" >
        <template v-if="index == 0 || index == 1"> 
          <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="true" v-if="index == 0" id="step-1"/>
          <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="true" v-else/>
        </template>
        <IndividualGameRoom :key="rooms.title" :gameDetails="rooms" :isPrivate="isPrivate" :positionTop="false" v-else/>
      </template>    
    </div>

  </div>
</template>

<script>
import IndividualGameRoom from './IndividualGameRoom';
import DashboardTour from '@/components/DashboardTour';

import { gameUpdateOrder } from '@/services/adminAPI/game.js';

import draggable from 'vuedraggable'

import {eventBus} from '@/main.js';

export default {
  props:['gameRooms','isPrivate'],
  components:{
    IndividualGameRoom,
    DashboardTour,
    draggable,
  },
  data(){
    return {
      draggableData: [],
      updatedList: []
    }
  },
  mounted(){
    this.draggableData = this.gameRooms;
  },
  methods:{
    async updateGameOrder(){
      const body = this.draggableData.map((data,index) => {
        return {
          id: data.id,
          order: index + 1
        }
      });
      
      try {
        eventBus.$emit('loader',true);
        const gameUpdateOrderAPI = await gameUpdateOrder({games: body});

        if(!gameUpdateOrderAPI.data.error){
          await this.$store.dispatch("callGameList");
        }
      } catch (error) {
        console.log(error);
      }
      eventBus.$emit('loader',false);
    },
  },
  watch:{
    gameRooms(data){
      this.draggableData = data;
    },
  }
}
</script>

<style scoped lang="scss">
.game-list{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit,485px);

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }

}
</style>